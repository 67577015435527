import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import style from './BackBtn.module.scss'
import { Link } from '../../../i18n'

const BackBtn = ({ text, path }) => {
  return (
    <button className={style.backBtn}>
      <Link to={`${path}`}>
        <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </Link>
    </button>
  )
}

BackBtn.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
}

BackBtn.defaultProps = {
  text: 'назад',
  path: '/#',
}

export default BackBtn
