import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../../layouts/default/defaultLayout'
import { withIntl } from '../../i18n'
import DemoSingleLayout from '../../layouts/demo/DemoSingleLayout'
import GlassesPage from '../../layouts/demo/demos/glasses/GlassesPage'

class DemoGlasses extends React.Component {
  state = {
    title: this.props.intl.messages.demoPage.title,
    config: this.props.intl.messages.demoPage.demoGlasses,
    btnBack: this.props.intl.messages.demoPage.btnBack,
    uploadText: this.props.intl.messages.demoPage.uploadText,
  }

  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { title, config, btnBack, uploadText } = this.state
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={title}
        />
        <DemoSingleLayout config={config} btnBack={btnBack}>
          <GlassesPage uploadText={uploadText} />
        </DemoSingleLayout>
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(DemoGlasses))
