import React, { PureComponent } from 'react'
import ApiManager from '../../api/ApiManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './GlassesPage.module.scss'
import layout from '../../DemoSingeLayout.module.scss'
import PropTypes from 'prop-types'

class GlassesPage extends PureComponent {
  static propTypes = {
    uploadText: PropTypes.string,
  }

  static defaultProps = {
    uploadText: 'Для загрузки, перетащите файл сюда.',
  }

  value = ''
  fullImgURL = ''
  ghostImgURL = ''
  apiHost = 'glasses?'
  rectangles = []

  addLoader = () => {
    if (this.loader) {
      this.loader.classList.add(layout.loaderActive)
      this.loader.scrollIntoView({ behavior: 'smooth' })
    }
  }

  removeLoader = () => {
    if (this.loader) this.loader.classList.remove(layout.loaderActive)
  }

  chooseImage = el => {
    if (el.target.files.length > 0) {
      this.fullImgURL = window.URL.createObjectURL(el.target.files[0])
    }
    this.forceUpdate()
  }

  uploadImage = imageData => {
    const imageUrl = window.URL.createObjectURL(imageData)
    this.setFullImage(imageUrl)
    this.addLoader()
    ApiManager.sendRequest(
      this.apiHost,
      ApiManager.parseFaces,
      data => {
        this.removeLoader()
        this.drawData(data)
      },
      undefined,
      imageData
    )
  }

  setFullImage(source) {
    this.fullImgURL = source
    this.forceUpdate()
  }

  setGhostImage(source) {
    this.ghostImgURL = source
    this.forceUpdate()
  }

  ondragover = () => {
    this.hover = true
    return false
  }

  drawData = data => {
    const canvas = this.hiddenCanvas
    const img = this.fullImage
    const ghost = this.ghostImage
    canvas.width = img.width
    canvas.height = img.height
    ghost.width = img.width
    ghost.height = img.height

    if (canvas.getContext) {
      const ctx = canvas.getContext('2d')
      let rectangle = new Path2D()
      for (let i = 0; i < data.length; i++) {
        const rect = JSON.parse(data[i]['rectangle'])
        const num = (data[i]['score'] * 100).toFixed(2) + '%'
        ctx.font = '28px Calibri'
        if ((data[i]['score'] * 100).toFixed(2) < 50) {
          ctx.fillStyle = '#FF0000'
        } else {
          ctx.fillStyle = 'green'
        }
        ctx.strokeStyle = 'blue'
        ctx.lineWidth = 5

        ctx.fillText(num, rect[0] * (img.width / img.naturalWidth) + 3, rect[1] * (img.height / img.naturalHeight) + 25)
        rectangle.rect(
          rect[0] * (img.width / img.naturalWidth),
          rect[1] * (img.height / img.naturalHeight),
          (rect[2] - rect[0]) * (img.width / img.naturalWidth),
          (rect[3] - rect[1]) * (img.height / img.naturalHeight)
        )
      }
      ctx.stroke(rectangle)
    }
    let url = canvas.toDataURL()
    this.setGhostImage(url)
  }

  uploadUrl = imageUrl => {
    this.setFullImage(imageUrl)
    this.addLoader()
    ApiManager.sendRequest(
      this.apiHost,
      ApiManager.parseFaces,
      data => {
        this.removeLoader()
        this.drawData(data)
      },
      { picUrl: imageUrl }
    )
  }

  onDrop = event => {
    this.hover = false
    event.preventDefault()
    if (event.dataTransfer.files.length === 0) {
      const url = event.dataTransfer.getData('text/uri-list')
      this.uploadUrl(url)
    } else {
      const file = event.dataTransfer.files[0]
      this.uploadImage(file)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={layout.loader}
          ref={loader => {
          this.loader = loader
        }}>
          <i></i>
          <span>Идёт распознавание...</span>
        </div>
        <div
          onDragOver={e => {
            this.hover = true
            e.preventDefault()
            return false
          }}
          onDragLeave={() => {
            this.hover = false
            return false
          }}
          onDrop={this.onDrop}
        >
          <input
            style={{ display: 'none' }}
            type="file"
            ref={input => {
              this.aInput = input
            }}
            onChange={el => {
              if (el.target.files.length > 0) {
                this.uploadImage(el.target.files[0])
              }
              this.forceUpdate()
            }}
          />
          <div
            onClick={() => {
              this.aInput.click()
            }}
            className={style.uploadContainer}
          >
            <div style={{ display: this.fullImgURL === '' ? 'none' : 'block', marginTop: this.fullImgURL === '' ? 0 : 75 }}>
              <div>
                <img
                  alt=""
                  style={{ display: 'none' }}
                  ref={ghost => {
                    this.ghostImage = ghost
                  }}
                />
                <img
                  alt=""
                  ref={img => {
                    this.fullImage = img
                  }}
                  src={this.fullImgURL}
                  style={{
                    display: 'block',
                    position: 'relative',
                    margin: 'auto',
                    width: '100%',
                  }}
                />
                <canvas
                  className={style.canvas}
                  ref={canvas => {
                    this.hiddenCanvas = canvas
                  }}
                />
              </div>
            </div>
            <div className={style.uploadBg} style={{ display: this.fullImgURL !== '' ? 'none' : 'flex' }}>
              <FontAwesomeIcon size="10x" icon={['fas', 'cloud-upload-alt']} />
              <p dangerouslySetInnerHTML={{ __html: this.props.uploadText }} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default GlassesPage
