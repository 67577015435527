import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import style from './DemoSingeLayout.module.scss'
import BackBtn from '../../components/buttons/backBtn/BackBtn'

class DemoSingleLayout extends PureComponent {
  state = {
    btnBack: this.props.btnBack,
    config: this.props.config,
  }

  static propTypes = {
    btnBack: PropTypes.string,
    config: PropTypes.object,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    btnBack: 'Назад',
  }

  render() {
    const { btnBack, config } = this.state
    return (
      <div className={classnames(style.demoSingle, style.container, style.basicBlock)}>
        <BackBtn path="/demos" text={btnBack} />
        <h3>{config.heading}</h3>
        <div className={style.innerContainer}>
          <div className={style.demoDescription} dangerouslySetInnerHTML={{ __html: config.description }} />
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DemoSingleLayout
