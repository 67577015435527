class ApiManager {
  address = 'https://fwd.ashmanov.net/demos-12992/'

  sendRequestForPic(requestString, parser, handler, headers = {}, body, errorHandler) {
    return this.sendRequest(requestString, parser, handler, headers, body, errorHandler, 'arraybuffer')
  }

  sendRequest(requestString, parser, handler, headers = {}, body, errorHandler, responseType) {
    let xhr = new XMLHttpRequest()
    xhr.onreadystatechange = this.getResponse(parser, handler, errorHandler)
    if (responseType !== undefined) {
      xhr.responseType = responseType
    }
    let requestType = 'POST'
    if (body === undefined) {
      requestType = 'GET'
    }
    xhr.open(requestType, this.address + requestString, true)
    if (headers !== undefined) {
      for (let header in headers) {
        if (headers.hasOwnProperty(header)) {
          xhr.setRequestHeader(header, headers[header])
        }
      }
    }
    if (body === undefined) {
      xhr.send()
    } else {
      xhr.send(body)
    }
  }

  parseFaces = jsonString => {
    return JSON.parse(jsonString)['faces']
  }

  parsePlaces = message => {
    return message.split('\n')
  }

  parsePorn = message => {
    return [message]
  }

  parseStylist = message => {
    const byteArray = message
    let binData = []
    binData.push(byteArray)
    return window.URL.createObjectURL(new Blob(binData, { type: 'image/jpeg' }))
  }

  parseError = jsonString => {
    // return JSON.parse(jsonString).code;
    return 0
    // TODO: parse error
  }

  getResponse(parser, handler, errorHandler) {
    return event => {
      if (event.target.readyState === 4) {
        if (event.target.status === 200) {
          if (event.target.responseType === '' || event.target.responseType === 'document') {
            const code = this.parseError(event.target.responseText)
            if (code === 0) {
              if (handler !== undefined && parser !== undefined) {
                handler(parser(event.target.responseText))
              }
            } else {
              if (errorHandler !== undefined) {
                errorHandler(code)
              }
            }
          } else if (event.target.responseType === 'arraybuffer') {
            if (handler !== undefined && parser !== undefined) {
              handler(parser(event.target.response))
            }
          }
        }
      }
    }
  }
}

export default new ApiManager()
